// Compatible with @pl/pl-theme 0.1.0
//insert variable or global CSS overrides here

:root {
  --pl-sidebar-color: white;
  --pl-sidebar-background: #323232;

  --kendo-drawer-item-active-bg: var(--pl-sidebar-color);

  --kendo-drawer-item-hover-bg: color-mix(
    in lch,
    var(--pl-sidebar-background),
    var(--pl-sidebar-color)
  );
  --kendo-drawer-item-expanded-bg: var(--pl-sidebar-background);
  --kendo-drawer-item-selected-icon-text: var(--pl-sidebar-background);

  --kendo-drawer-border-width: 0;
}

@font-face {
  font-family: PremierLeagueHeavy;
  src: url('../../../../assets/fonts/PremierLeague-Heavy.otf');
  font-weight: bold;
}

.metadata-form {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  background-color: #efefef;
  padding: 16px 0 16px 15px;

  app-pl-input-wrapper,
  app-pl-form-item {
    margin-top: 0;
    margin-right: 16px;
    min-width: 300px;
  }
}

pl-lib-form-content-layout > .wrapper > .content {
  padding: 0 16px 16px;
}

.form-fields {
  .form-line {
    display: flex;
    gap: 16px;

    > * {
      flex: 1;
    }
  }
}

.sidebar-preview {
  display: flex;
  padding: 12px;
  align-items: center;
}

.word-break {
  white-space: pre;
}
.pl-drawer-link.active {
  color: var(--pl-sidebar-background) !important;
}

.metadata-form {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  background-color: #efefef;
  padding: 16px 0 16px 15px;

  app-pl-input-wrapper,
  app-pl-form-item {
    margin-top: 0;
    margin-right: 16px;
    min-width: 300px;
  }
}

pl-lib-form-content-layout > .wrapper > .content {
  padding: 0 16px 16px;
}

kendo-drawer .k-drawer-wrapper .k-drawer-items .k-drawer-item {
  background-color: initial!important;
}

.form-fields {
  .form-line {
    display: flex;
    gap: 16px;

    > * {
      flex: 1;
    }
  }
}

.sidebar-preview {
  display: flex;
  padding: 12px;
  align-items: center;
}


.word-break {
  white-space: pre;
}