/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@pl/pl-theme/src/lib/default-theme';
@import '../node_modules/@pl/pl-theme/src/lib/fonts';
@import '../node_modules/@pl/pl-theme/src/lib/variables';

@import '../src/app/plLibSetup/theme/styles/overrides';

@tailwind base;
@tailwind components;
@tailwind utilities;

// To override tailwind [type='button'] class which was breaking kendo styles for dropdown buttons.
// Will need to add another .k-button... classes as needed.
button, [type='button'], [type='reset'], [type='submit'] {
  &.k-button {
    @extend .k-button;
  }
  &.k-icon-button {
    @extend .k-icon-button;
  }
  &.k-button-solid-primary {
    @extend .k-button-solid-primary;
  }
}

html, body {
  margin: 0;
}

.root-placeholder {
  .root-spinner,
  .root-content {
    color: var(--kendo-primary-100);
  }
}

@media (max-width: 800px) {
  pl-entity-list .k-grid .k-grid-aria-root {
    width: 175%;
  }
}

kendo-drawer[ng-reflect-expanded="false"] {
  img#logo {
      display: none;
  }
}


  [ng-reflect-table-id="pending-approvals"], [ng-reflect-table-id="coaches"] {
    .k-table-td > span {
      display: none;
    }
  }